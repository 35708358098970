import Articles from "./modules/Articles";
import Login from "./modules/Login";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import CreateAccount from "./modules/CreateAccount";
import {useEffect, useState, useMemo, useCallback} from "react";
import {ACCOUNT_UUID, AUTH, MODE, ROUTE_CONSTANT, STORE, STORE_UUID, USER} from "./constants/app.constants";
import Page404 from "./modules/404";
import AppContext from "./context/AppContext";
import HomeCheck from "./modules/HomeCheck";
import AllBlogs from "./modules/AllBlogs";
import Analytics from "./modules/Analytics";
import Configure from "./modules/Configure";
import Widgets from "./modules/Widgets";
import Settings from "./modules/Settings";
import Notepad from "./modules/Notepad";
import EditorPage from "./modules/EditorPage";
import {Toaster} from "react-hot-toast";
import Dashboard from "./modules/Dashboard";
import CreateBlogPage from "./modules/CreateBlogPage";
import PricingPlanPage from "./modules/PricingPlanPage";
import PricingFailurePage from "./modules/PricingFailurePage";
import PricingVerifyPage from "./modules/PricingVerifyPage";
import OnboardAccount from "./modules/OnboardAccount";
import CreateArticlePage from "./modules/CreateArticlePage";
import ArticlesEditorPage from "./modules/ArticlesEditorPage";
import QuillEditorContext from "./components/quill/context/QuillEditorContext";
import GeneratingArticlePage from "./modules/GeneratingArticlePage";
import mixpanel from 'mixpanel-browser';
import Confetti from "react-confetti";
import InternalLink from "./modules/InternalLink";
import { initializeIntercom } from "./utils/intercom";
import AdminTesting from "./modules/AdminTesting";

mixpanel.init('bf3c4a803eb4749dacd1149abef4f09d');


function App() {
    const [appState, setAppState] = useState({
        user: null,
        token: null,
        account: null,
        store: null,
        mode: null,
        isAuthenticated: false,
        initialDataFetch: false,
        showConfetti: false,
    });
    
    const [editorState, setEditorState] = useState({
        editorIndex: null,
        popupPosition: {top: 0, left: 0},
        selectedEditorText: "",
        articleHtml: "",
    });
    
    const [contentSummary, setContentSummary] = useState({});
    
    const afterLogin = useCallback(() => {
        setAppState((prevState) => ({...prevState, isAuthenticated: true}));
    }, []);
    
    const afterLogout = useCallback(() => {
        localStorage.removeItem(AUTH);
        localStorage.removeItem(USER);
        localStorage.removeItem(STORE);
        localStorage.removeItem(STORE_UUID);
        localStorage.removeItem(ACCOUNT_UUID);
        setAppState((prevState) => ({
            ...prevState,
            user: null,
            token: null,
            account: null,
            store: null,
            isAuthenticated: false,
        }));
    }, []);
    
    const storeData = useCallback((key, value, stateKey) => {
        localStorage.setItem(key, value);
        setAppState((prevState) => ({
            ...prevState,
            [stateKey]: value,
        }));
    }, []);
    
    const handleContentSummary = useCallback((summary) => {
        setContentSummary(summary);
    }, []);
    
    useEffect(() => {
        const storedToken = localStorage.getItem(AUTH);
        const storedUser = localStorage.getItem(USER);
        const storedAccount = localStorage.getItem(ACCOUNT_UUID);
        const storedStore = localStorage.getItem(STORE_UUID);
        const storedMode = localStorage.getItem(MODE);
        
        setAppState({
            token: storedToken || null,
            user: storedUser ? JSON.parse(storedUser) : null,
            account: storedAccount || null,
            store: storedStore || null,
            mode: storedMode || null,
            isAuthenticated: !!storedToken && !!storedUser,
            initialDataFetch: true,
            showConfetti: false,
        });

        // Initialize Intercom (chat widget) with user data
        initializeIntercom({
            name: storedUser ? JSON.parse(storedUser).name : "",
            email: storedUser ? JSON.parse(storedUser).email : "",
        });

    }, []);
    
    const triggerConfetti = useCallback(() => {
        setAppState((prevState) => ({
            ...prevState,
            showConfetti: true,
            numberOfPieces: 200,
        }));
        
        const interval = setInterval(() => {
            setAppState((prevState) => {
                if (prevState.numberOfPieces > 0) {
                    return {...prevState, numberOfPieces: prevState.numberOfPieces - 10};
                } else {
                    clearInterval(interval);
                    return {...prevState, showConfetti: false};
                }
            });
        }, 400);
    }, []);
    
    const routes = useMemo(
        () => (
            <Routes>
                <Route path={ROUTE_CONSTANT.DASHBOARD} exact element={<Dashboard/>}/>
                <Route path={ROUTE_CONSTANT.BLOGS} exact element={<AllBlogs/>}/>
                <Route path={ROUTE_CONSTANT.CREATE_BLOG} exact element={<CreateBlogPage/>}/>
                <Route path={ROUTE_CONSTANT.CREATE_ARTICLE} exact element={<CreateArticlePage/>}/>
                <Route path={ROUTE_CONSTANT.ARTICLES} exact element={<Articles/>}/>
                <Route path={ROUTE_CONSTANT.ANALYTICS} exact element={<Analytics/>}/>
                <Route path={ROUTE_CONSTANT.CONFIGURE} exact element={<Configure/>}/>
                <Route path={ROUTE_CONSTANT.SETTINGS} exact element={<Settings/>}/>
                <Route path={ROUTE_CONSTANT.WIDGETS} exact element={<Widgets/>}/>
                <Route path={ROUTE_CONSTANT.INTERNAL_LINK} element={<InternalLink/>}/>
                <Route path={ROUTE_CONSTANT.NOTEPAD} exact element={<Notepad/>}/>
                <Route path={ROUTE_CONSTANT.EDITOR_PAGE} exact element={<EditorPage/>}/>
                <Route path={ROUTE_CONSTANT.PRICING_PLAN_PAGE} exact element={<PricingPlanPage/>}/>
                <Route path={ROUTE_CONSTANT.PRICING_FAILURE_PAGE} exact element={<PricingFailurePage/>}/>
                <Route path={ROUTE_CONSTANT.PRICING_VERIFY_PAGE} exact element={<PricingVerifyPage/>}/>
                <Route path={ROUTE_CONSTANT.GENERATING_ARTICLE_PAGE} exact element={<GeneratingArticlePage/>}/>
                <Route path={ROUTE_CONSTANT.TESTING} exact element={<AdminTesting/>}/>
                <Route path="/" exact element={<HomeCheck/>}/>
                <Route path={ROUTE_CONSTANT.LOGIN} exact element={<Login/>}/>
                <Route path={ROUTE_CONSTANT.CREATE_ACCOUNT} exact element={<CreateAccount/>}/>
                <Route path={ROUTE_CONSTANT.ONBOARDING_ACCOUNT} exact element={<OnboardAccount/>}/>
                {/* <Route path={ROUTE_CONSTANT.INTERNAL_LINK} element={<InternalLink/>}/> */}
                <Route path={ROUTE_CONSTANT.NOT_FOUND} element={<Page404/>}/>
            </Routes>
        ),
        []
    );
    
    return (
        <>
            <AppContext.Provider
                value={{
                    ...appState,
                    afterLogin,
                    afterLogout,
                    storeUserData: (user) => storeData(USER, JSON.stringify(user), "user"),
                    storeTokenData: (token) => storeData(AUTH, token, "token"),
                    storeAccountData: (accountUuid) => storeData(ACCOUNT_UUID, accountUuid, "account"),
                    storeStoreData: (storeUuid) => storeData(STORE_UUID, storeUuid, "store"),
                    storeModeData: (modeValue) => storeData(MODE, modeValue, "mode"),
                    contentSummary,
                    setContentSummary: handleContentSummary,
                    triggerConfetti
                }}
            >
                <QuillEditorContext.Provider
                    value={{
                        ...editorState,
                        updateEditorIndex: (index) => setEditorState((prevState) => ({
                            ...prevState,
                            editorIndex: index
                        })),
                        updatePopupPosition: (position) =>
                            setEditorState((prevState) => ({...prevState, popupPosition: position})),
                        updateSelectedText: (text) =>
                            setEditorState((prevState) => ({...prevState, selectedEditorText: text})),
                        updateArticleHtml: (html) =>
                            setEditorState((prevState) => ({...prevState, articleHtml: html})),
                    }}
                >
                    <BrowserRouter>{routes}</BrowserRouter>
                    
                    <Toaster/>
                    
                    {appState.showConfetti && (
                        <Confetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                            numberOfPieces={500}
                            recycle={false}/>
                    )}
                </QuillEditorContext.Provider>
            </AppContext.Provider>
        </>
    );
}

export default App;
