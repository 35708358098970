import React, {useContext, useEffect, useState} from 'react'
import Editor from "./Editor/Editor.jsx";
import classNames from 'classnames';
import {useNavigate, useParams} from "react-router";
import {AUTH, ROUTE_CONSTANT, STORE_UUID, USER} from "../constants/app.constants";
import AppContext from "../context/AppContext";
import {Link, useLocation} from "react-router-dom";
import API from "../api/api";
import {toastError, toastSuccess, toastWarning} from "../context/ToasterContext";
import {cssContent} from "../constants/style.constants";
import DeployForm from "../components/shared/DeployForm";
import AppModalShort from "../components/shared/AppModalShort";
import AppModalMedium from "../components/shared/AppModalMedium";
import AppModalLong from "../components/shared/AppModalLong";
import ChooseTemplate from "./ChooseTemplate";
import SEOReportView from "../components/layouts/SEOReportView";
import mixpanel from "mixpanel-browser";


function EditorPage() {
    
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store, contentSummary} = useContext(AppContext);
    const [isEditorMode, setIsEditorMode] = useState(true);
    const [text, setText] = useState('');
    const [html, setHtml] = useState("");
    const [articleId, setArticleId] = useState("");
    const [article, setArticle] = useState({});
    const [articleHtml, setArticleHtml] = useState("");
    const [articleJson, setArticleJson] = useState(null);
    const [blogId, setBlogId] = useState("");
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("");
    const location = useLocation();
    const {article_id, blog_id, blog_uuid, blog_title, blog_handle, article_uuid} = location.state;
    const url = location.pathname;
    const {2: blog_name, 4: article_name} = url.split('/');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [isEditArticleDetailModalOpen, setIsEditArticleDetailModalOpen] = useState(false);
    const [articleTitle, setArticleTitle] = useState("");
    const [articleSlug, setArticleSlug] = useState("");
    const [articleAuthor, setArticleAuthor] = useState("");
    const [articleCategory, setArticleCategory] = useState("");
    const [editArticleSaving, setEditArticleSaving] = useState(false);
    const [showCopied, setShowCopied] = useState(false);
    const [currentBlog, setCurrentBlog] = useState({});
    const [isPublishSelected, setIsPublishSelected] = useState(false);
    const [showSEOReport, setShowSEOReport] = useState(false);
    const [showSEOReportView, setShowSEOReportView] = useState(false);
    const [articleContent, setArticleContent] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    
    const [showAddCategoryField, setShowAddCategoryField] = useState(false);
    const [showAddAuthorField, setShowAddAuthorField] = useState(false);
    const [addNewCategory, setAddNewCategory] = useState('');
    const [addNewAuthor, setAddNewAuthor] = useState('');
    const [authors, setAuthors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [slugValid, setSlugValid] = useState(false);
    const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
    
  const [blogDetails, setBlogDetails] = useState({});

    // loading states
    const [publishButtonLoading, setPublishButtonLoading] = useState(false);
    const [unpublishButtonLoading, setUnpublishButtonLoading] = useState(false);
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
    const [saveButtonLoading, setSaveButtonLoading] = useState(false);
    const [saveAsDraftButtonLoading, setSaveAsDraftButtonLoading] = useState(false);
    
    const handleSlugChange = (e) => {
        const value = e.target.value;
        setArticleSlug(value);
        setSlugValid(slugRegex.test(value));
    };
    
    useEffect(() => {
        setSlugValid(slugRegex.test(articleSlug));
    }, [articleSlug]);
    
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    
    const handleClosePublishModal = () => {
        setIsPublishModalOpen(false);
    };
    
    const handleOpenEditArticleDetailModal = () => {
        setIsEditArticleDetailModalOpen(true);
    };
    
    const handleCloseEditArticleDetailModal = () => {
        setIsEditArticleDetailModalOpen(false);
    };
    
    const handleEditArticleDetail = () => {
        if (slugValid && articleTitle) {
            setEditArticleSaving(true);
            const payload = {
                title: articleTitle,
                handle: articleSlug,
                author: articleAuthor,
                categories: articleCategory
            };
            // console.log("payload: ", payload);
            let headerParams = {
                headers: {
                    "Authorization": token
                }
            };
            API.post(`articles/edit-article-title-and-handle/${account}/${store}/${blog_uuid}/${article_uuid}`, payload, headerParams)
                .then(res => {
                    setEditArticleSaving(false);
                    setIsEditArticleDetailModalOpen(false);
                    toastSuccess("Saved your changes");
                    handleFetchArticle();
                })
                .catch(error => {
                    setLoading(false);
                    toastError(error.message);
                })
        }
    }

      //fetch blog details during pageLoad
  useEffect(() => {
    if (initialDataFetch) {
      if (!!token && !!user && !!store) {
        getBlogDetails();
      }
    }}, [initialDataFetch]);

    //get blod details
    const getBlogDetails = async () => {
      let headerParams = {
          headers: {
              "Authorization": token
          }
      };
      API.get(`blog/get-blog-details/${account}/${store}/${blog_uuid}`, headerParams)
          .then(res => {
              const data = res.data.data;
              if (!!data) {
               setBlogDetails(data);
              }
          })
          .catch(error => {
              console.log('Error: ', error.message);
          });
    }

    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                handleFetchArticle();
                handleGetAuthors();
                handleGetCategories();
                setCurrentBlog({
                    name: blog_name,
                    uuid: blog_uuid,
                    title: blog_title,
                    handle: blog_handle,
                    id: blog_id,
                    article_uuid,
                    article_id
                })
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [location, token, user]);
    
    
    const handleFetchArticle = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.get(`articles/get-article/${account}/${store}/${blog_uuid}/${article_uuid}`, headerParams)
            .then(res => {
                if (!!res.data.data) {
                    const {data} = res.data;
                    setArticle(data);
                    setArticleTitle(data.title);
                    setArticleSlug(data.handle);
                    setArticleAuthor(data.author);
                    setArticleCategory(data.categories);
                    setHtml(res.data.data.article_html);
                    setLoading(false);
                } else {
                    setArticle({});
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                setError(error.message);
            })
    }
    
const compressImage = async (imgTag, base64Src) => {
    // Extract width and height from img tag if present
    const widthMatch = imgTag.match(/width="([^"]*)"/);
    const heightMatch = imgTag.match(/height="([^"]*)"/);
    const styleMatch = imgTag.match(/style="[^"]*width:\s*(\d+)px/);
    
    // Get dimensions from either width/height attributes or style
    let targetWidth = widthMatch ? parseInt(widthMatch[1]) : null;
    if (!targetWidth && styleMatch) {
        targetWidth = parseInt(styleMatch[1]);
    }
    
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            
            // Calculate dimensions
            let width = img.width;
            let height = img.height;
            const MAX_WIDTH = 2000; // Maximum allowed width
            const MAX_HEIGHT = 2000; // Maximum allowed height
            
            // If Quill editor has resized the image, use those dimensions
            // but ensure they don't exceed our maximums
            if (targetWidth) {
                const aspectRatio = img.width / img.height;
                width = Math.min(targetWidth, MAX_WIDTH);
                height = Math.round(width / aspectRatio);
                
                if (height > MAX_HEIGHT) {
                    height = MAX_HEIGHT;
                    width = Math.round(height * aspectRatio);
                }
            } else {
                // If no Quill dimensions, scale down if exceeds maximum
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            
            // Set canvas dimensions
            canvas.width = width;
            canvas.height = height;
            
            // Draw and compress
            ctx.drawImage(img, 0, 0, width, height);
            
            // Adjust compression based on image size
            let quality = 0.7;
            const totalPixels = width * height;
            if (totalPixels > 4000000) { // For very large images
                quality = 0.6;
            } else if (totalPixels < 1000000) { // For smaller images
                quality = 0.8;
            }
            
            const compressedBase64 = canvas.toDataURL('image/jpeg', quality);
            resolve(compressedBase64.split(',')[1]);
        };
        img.src = base64Src;
    });
};
// save as draft
const handleSaveAsDraft = async () => {
    try {
        setSaveAsDraftButtonLoading(true);
        const headerParams = {
            headers: {
                "Authorization": token
            }
        };

        let processedContent = articleContent;
        const imgTags = articleContent.match(/<img[^>]*>/g) || [];
        
        for (const imgTag of imgTags) {
            const srcMatch = imgTag.match(/src="([^"]*)"/);
            if (!srcMatch) continue;
            
            const src = srcMatch[1];
            
            // Skip if not base64
            if (!src.startsWith('data:image/')) continue;
            
            try {
                // Compress image while considering Quill's dimensions
                const compressedImage = await compressImage(imgTag, src);
                
                const params = {
                    image: compressedImage,
                    store_uuid: store,
                    account_uuid: account
                };
                
                const response = await API.post(
                    `articles/save-image/${account}/${store}/${blog_uuid}`,
                    params,
                    headerParams
                );
                
                const newSrc = response.data.data.image_url;
                // Replace only the src attribute while preserving width, height and other attributes
                const newImgTag = imgTag.replace(/src="[^"]*"/, `src="${newSrc}"`);
                processedContent = processedContent.replace(imgTag, newImgTag);
                
            } catch (error) {
                console.error('Error processing image:', error);
                if (error.response?.status === 413) {
                    toastError("Image size too large. Please use a smaller image or compress it further.");
                } else {
                    toastError(`Failed to save image: ${error.message}`);
                }
                                                //remove the image tag from the content if the image is not saved
                                                processedContent = processedContent.replace(imgTag, '');
            }
        }

        const bodyParams = {
            article_html: processedContent,
            is_published: false,
            article_id: article_id,
            content_summary: contentSummary
        };

        const saveResponse = await API.post(
            `articles/save-article-as-draft/${account}/${store}/${blog_uuid}`,
            bodyParams,
            headerParams
        );
        
        setSaveAsDraftButtonLoading(false);
        toastSuccess("Saved your changes");
        
    } catch (error) {
        setSaveAsDraftButtonLoading(false);
        toastError(error.message);
    }
};

//save article (publishable)
const handleSave = async () => {
    try {
        setSaveButtonLoading(true);
        const headerParams = {
            headers: {
                "Authorization": token
            }
        };

        let processedContent = articleContent;
        const imgTags = articleContent.match(/<img[^>]*>/g) || [];
        
        for (const imgTag of imgTags) {
            const srcMatch = imgTag.match(/src="([^"]*)"/);
            if (!srcMatch) continue;
            
            const src = srcMatch[1];
            
            // Skip if not base64
            if (!src.startsWith('data:image/')) continue;
            
            try {
                // Compress image while considering Quill's dimensions
                const compressedImage = await compressImage(imgTag, src);
                
                const params = {
                    image: compressedImage,
                    store_uuid: store,
                    account_uuid: account
                };
                
                const response = await API.post(
                    `articles/save-image/${account}/${store}/${blog_uuid}`,
                    params,
                    headerParams
                );
                
                const newSrc = response.data.data.image_url;
                // Replace only the src attribute while preserving width, height and other attributes
                const newImgTag = imgTag.replace(/src="[^"]*"/, `src="${newSrc}"`);
                processedContent = processedContent.replace(imgTag, newImgTag);
            } catch (error) {
                console.error('Error processing image:', error);
                if (error.response?.status === 413) {
                    toastError("Image size too large. Please use a smaller image or compress it further.");
                } else {
                    toastError(`Failed to save image: ${error.message}`);
                }
                                                //remove the image tag from the content if the image is not saved
                                                processedContent = processedContent.replace(imgTag, '');
            }
        }

        const bodyParams = {
            article_html: processedContent,
            is_published: false,
            article_id: article_id,
            content_summary: contentSummary
        };

        const saveResponse = await API.post(
            `articles/save-article/${account}/${store}/${blog_uuid}`,
            bodyParams,
            headerParams
        );
        
        setSaveButtonLoading(false);
        toastSuccess("Saved your changes");
        
    } catch (error) {
        setSaveButtonLoading(false);
        toastError(error.message);
    }
};

    // article Publishing
    const publishArticle = async () => {
        try {
            setPublishButtonLoading(true);
            mixpanel.track('Publish Clicked', {
                "article_id": article.article_id,
            });
            const headerParams = {
                headers: {
                    "Authorization": token
                }
            };
            const payload = {
                article_uuid: article_uuid,
            }
            const articlePublish = await API.post(`articles/publish-article/${account}/${store}/${blog_uuid}/${article_uuid}`, payload, headerParams);
            setPublishButtonLoading(false);
            if(articlePublish.status === 200){ 
            toastSuccess("Published your article");
            navigate(`/blogs/${blog_name}/articles`, {
                state: {
                    blog_id,
                    blog_uuid,
                    blog_title,
                    blog_handle
                }
            });
        } else if (articlePublish.status === 201) {
            toastWarning("Your article is already published");
        } else if (articlePublish.status === 203) {
            toastWarning("Please Publish Your Blog First");
        }
        } catch (error) {
            setPublishButtonLoading(false);
            console.log("error: ", error);
            toastError(error.message);
        }
    }

    //article unpublish
    const unpublishArticle = async () => {
        try {
            setUnpublishButtonLoading(true);

            const headerParams = {
                headers: {
                    "Authorization": token
                }
            };
            const payload = {
                article_uuid: article_uuid,
            }
            const articleUnpublish = await API.post(`articles/unpublish-article/${account}/${store}/${blog_uuid}/${article_uuid}`, payload, headerParams);

            setUnpublishButtonLoading(false);
            toastSuccess("Unpublished your article");
            navigate(`/blogs/${blog_name}/articles`, {
                state: {
                    blog_id,
                    blog_uuid,
                    blog_title,
                    blog_handle
                }
            });
        } catch (error) {
            setUnpublishButtonLoading(false);
            toastError(error.message);
        }
    }


    
    const handleCopyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setShowCopied(true);
            setTimeout(() => {
                setShowCopied(false);
            }, 2000);
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    
    const toggleMode = () => {
        setIsEditorMode(!isEditorMode);
    };
    
    const previewClasses = classNames({
        'hidden': isEditorMode,
        'block': !isEditorMode,
    });
    
    const editorClasses = classNames({
        'hidden': !isEditorMode,
        'block': isEditorMode,
    });
    
    let onEditorChangeValue = (htmlValue) => {
        console.log("htmlValue --------------------------- ", htmlValue);
        setArticleHtml(htmlValue);
    }
    
    const publishSelectedStatus = (status) => {
        setIsPublishSelected(status);
    }
    
    const showSEO = (value) => {
        setShowSEOReportView(value);
    }
    
    const showSEOReportStatus = (status) => {
        setShowSEOReportView(status);
        setIsPublishSelected(status);
    }
    
    // get authors and categories
    const handleGetAuthors = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`account/get-blog-authors/${account}`, headerParams)
            .then(res => {
                const {data} = res.data;
                setAuthors(data);
            })
            .catch(error => {
                console.log('Error: ', error.message);
            });
    };
    
    const handleGetCategories = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`account/get-blog-categories/${account}`, headerParams)
            .then(res => {
                const {data} = res.data;
                setCategories(data);
            })
            .catch(error => {
                console.log('Error: ', error.message);
            });
    };
    
    // add new author and category
    const handleAddNewCategory = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            "blog_categories": addNewCategory,
        };
        API.post(`account/add-blog-categories/${account}`, params, headerParams)
            .then(res => {
                handleGetCategories();
                setAddNewCategory('');
                setShowAddCategoryField(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
            });
    };
    
    const handleAddNewAuthor = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            "blog_authors": addNewAuthor,
        };
        API.post(`account/add-blog-authors/${account}`, params, headerParams)
            .then(res => {
                handleGetAuthors();
                setAddNewAuthor('');
                setShowAddAuthorField(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
            });
    };
    
    //handle delete article
    const handleDeleteArticle = async () => {
        try {
            setDeleteButtonLoading(true);
            const headerParams = {
                headers: {
                    "Authorization": token
                }
            };
            const deleteResponse = await API.post(`articles/delete-article/${account}/${store}/${blog_uuid}/${article_uuid}`, headerParams);
            setDeleteButtonLoading(false);
            toastSuccess("Deleted your article");
            navigate(`/blogs/${blog_name}/articles`, {
                state: {
                    blog_id,
                    blog_uuid,
                    blog_title,
                    blog_handle
                }
            });
        } catch (error) {
            setDeleteButtonLoading(false);
            toastError(error.message);
        }
    }

    
    return (
        <div>
            
            {/*<TopNav/>*/}
            
            {!isPublishSelected && <>
                {!loading && error.length === 0 &&
                    <>
                        <div className="mx-auto mt-4">
                            <div className="flex mx-4 mb-2">
                                <div className="text-left -pt-12">
                                    <div className={"mb-2"}>
                                        <div className="flex">
                                            <div className="mr-5 mt-2 cursor-pointer"
                                                 onClick={() => {
                                                     navigate(`/blogs/${blog_name}/articles`, {
                                                         state: {
                                                             blog_id,
                                                             blog_uuid,
                                                             blog_title,
                                                             blog_handle
                                                         }
                                                     })
                                                 }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
                                                </svg>
                                            </div>
                                            
                                            <div>
                                                <div className="flex">
                                                    <div>
                                                        <div className="text-sm font-bold text-black">{article.title}</div>
                                                    </div>
                                                    <div className="ml-2 cursor-pointer"
                                                         onClick={handleOpenEditArticleDetailModal}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24"
                                                             strokeWidth="1.5" stroke="currentColor"
                                                             className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                
                                                <div className="flex mb-2">
                                                    <div className="flex mt-2 mr-5">
                                                        <div className="text-xs font-bold text_light_brown">Author: &nbsp;</div>
                                                        <div className="relative">
                                                            <p className="text-xs font-semibold cursor-pointer text-blue-500 hover:underline uppercase">{article.author}</p>
                                                        </div>
                                                        {/* <div className={"cursor-pointer"} onClick={() => {
                                                            handleOpenEditArticleDetailModal();
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                 viewBox="0 0 24 24"
                                                                 strokeWidth="1.5" stroke="currentColor"
                                                                 className="w-4 h-4 ml-2">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                                            </svg>
                                                        </div> */}
                                                    </div>
                                                    
                                                    
                                                    <div className="flex mt-2 mr-5">
                                                        <div className="text-xs font-bold text_light_brown">Category: &nbsp;</div>
                                                        <div className="relative">
                                                            <p className="text-xs font-semibold cursor-pointer text-blue-500 hover:underline uppercase">
                                                                {!!article.categories ? article.categories : "-"}
                                                            </p>
                                                        </div>
                                                        {/* <div className={"cursor-pointer"} onClick={() => {
                                                            handleOpenEditArticleDetailModal();
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                 viewBox="0 0 24 24"
                                                                 strokeWidth="1.5" stroke="currentColor"
                                                                 className="w-4 h-4 ml-2">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                                            </svg>
                                                        </div> */}
                                                    </div>
                                                    
                                                    
                                                    <div className="flex mt-2">
                                                        <div className="text-xs font-bold text_light_brown">Path: &nbsp;</div>
                                                        <div className="relative"
                                                             onClick={() => {
                                                                 handleCopyToClipboard(`${article.handle}`); //TODO: Change this to full url
                                                             }}>
                                                            <p className="text-xs font-semibold cursor-pointer text-blue-500 hover:underline">{article.handle}</p>
                                                        </div>
                                                        {/* <div className={"cursor-pointer"} onClick={() => {
                                                            handleOpenEditArticleDetailModal();
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                 viewBox="0 0 24 24"
                                                                 strokeWidth="1.5" stroke="currentColor"
                                                                 className="w-4 h-4 ml-2">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                                            </svg>
                                                        </div> */}
                                                        {showCopied &&
                                                            <p className="text-xs font-semibold cursor-pointer text-blue-500 ml-3">
                                                                Copied!
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                
                                </div>
                                
                                <div className="text-center flex-1">
                                </div>
                                
                                <div className="text-right">
                       
                                    <div className="flex mt-2 mb-2">
                                    <div className="flex items-center mx-2.5">
                                <span className={`text-sm font-bold text-primary`}>Editor Mode</span>
                                <label className="mx-2 relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" className="sr-only peer" checked={!isEditorMode} onChange={toggleMode} />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-orange-400 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-400"></div>
                                </label>
                                <span className={`text-sm font-bold text-secondary`}>Preview Mode</span>
                            </div>
                                        <button
                                            className={`text-center bg-gray-400 text-black
                                    focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2
                                    mr-2 focus:outline-none ${saveAsDraftButtonLoading ? "cursor-not-allowed" : ""} `}
                                            onClick={handleSaveAsDraft}
                                            disabled={saveAsDraftButtonLoading}>
                                            {saveAsDraftButtonLoading ? "Saving...." : "Save as Draft"}
                                        </button>

                                        <button
                                            className={`text-center bg-secondary text-black
                                    focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2
                                    mr-2 focus:outline-none ${saveButtonLoading ? "cursor-not-allowed" : ""}`}
                                            onClick={handleSave}
                                            disabled={saveButtonLoading}
                                            >
                                            {saveButtonLoading ? "Saving" : "Save"}
                                        </button>
                                        
                                    <button
                                            className={`text-white text-center bg-primary
                                    focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm max-w-content px-5 py-2
                                    mr-2 focus:outline-none ${publishButtonLoading ? "cursor-not-allowed" : ""}`}
                                            onClick={publishArticle}
                                            disabled={publishButtonLoading}>
                                           {/* {!article.is_published ? "Publish" : " Re-publish"} */}
                                           {publishButtonLoading ? "Publishing" : "Publish"}
                                        </button>
                                   
                                    
                                        
                                        {/* <button
                                            className={`ml-2 text-center underline font-bold ${isEditorMode ? "text-primary" : "text-secondary"}
                                    font-bold rounded-lg text-xs`}
                                            onClick={toggleMode}>
                                            {isEditorMode ? 'Preview Mode' : 'Editor Mode'}
                                        </button> */}
                                    
                                    <div className="relative inline-block text-left"></div>
                                        <button
                                            type="button"
                                            className={`text-black bg-gray-400 text-center
                                                font-extrabold rounded-lg text-2xl px-5 py-2
                                                mr-2 focus:outline-none`}
                                            id="menu-button"
                                            aria-expanded="true"
                                            aria-haspopup="true"
                                            onClick={() => setShowDropdown(!showDropdown)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75v.008M12 12v.008m0 5.242v.008" />
                                            </svg>
                                        </button>

                                        {showDropdown && (
                                            <div className="absolute top-16 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
                                                <div className="py-1" role="none">
                                                    <button
                                                        className={`text-gray-700 block px-4 py-2 text-sm font-semibold w-full text-left hover:bg-orange-300 ${unpublishButtonLoading ? "cursor-not-allowed" : ""}`}
                                                        role="menuitem"
                                                        onClick={unpublishArticle}
                                                        disabled={unpublishButtonLoading}
                                                    >
                                                        {unpublishButtonLoading? "Unpublishing" : "Unpublish Article"}
                                                    </button>
                                                   {blogDetails.is_domain_mapped && <button
                                                        className="text-gray-700 block px-4 py-2 text-sm font-semibold w-full text-left hover:bg-orange-300"
                                                        role="menuitem"
                                                        onClick={() => window.open(`https://${blogDetails.custom_domain || blogDetails.blogd_domain}/${article.handle}.html`, '_blank')}
                                                    >
                                                        View in URL
                                                    </button>}
                                                    <button
                                                        className={`text-gray-700 block px-4 py-2 text-sm font-semibold w-full text-left hover:bg-orange-300 ${deleteButtonLoading ? "cursor-not-allowed" : ""}`}
                                                        role="menuitem"
                                                        onClick={handleDeleteArticle}
                                                        disabled={deleteButtonLoading}
                                                    >
                                                        {deleteButtonLoading ? "Deleting" : "Delete Article"}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                </div>
                                
                                </div>
                          
                            </div>
                            
                            <div className={editorClasses + " -mt-3"}>
                                <Editor
                                    article={article}
                                    setArticle={setArticle}
                                    articleContent={html}
                                    setArticleContent={setArticleContent}
                                    placeholder={'Write something...'}
                                />
                            </div>
                            
                            {!isEditorMode &&
                                <div>
                                    <div
                                        className="max-w-5xl mb-6 flex mx-auto border rounded-2xl bg-white shadow border-gray-50 px-6 pb-6 mt-4">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `
                                    <style>
                                    ${cssContent}
                                    </style>
                                    <script>
                                    document.querySelectorAll('.tooltip').forEach(tooltip => {
    tooltip.addEventListener('mouseenter', () => {
        const tooltipText = tooltip.querySelector('.tooltiptext');
        const rect = tooltip.getBoundingClientRect();
        const tooltipRect = tooltipText.getBoundingClientRect();
        const space = {
            top: rect.top,
            bottom: window.innerHeight - rect.bottom,
            left: rect.left,
            right: window.innerWidth - rect.right,
        };

        // Determine the best position
        const position = Object.entries(space).sort((a, b) => b[1] - a[1])[0][0];
        tooltip.setAttribute('data-position', position);
    });
});

                                    </script>
                                    <div class="outer-body mx-6">
                                        <div class="text-3xl font-bold mt-6 leading-normal">
                                          ${article.title}
                                        </div>
                                        <div class="mt-2 mx-2">
                                          ${articleContent}
                                        </div>
                                    </div>
                                    `
                                            }}
                                        />
                                    </div>
                                </div>}
                        
                        </div>
                    </>}
            </>}
            
            {loading &&
                <div className="flex justify-center items-center mt-44">
                    <div className="text-center">
                        <h1 className="text-2xl font-bold">Loading...</h1>
                        <p className="text-gray-500">Please wait</p>
                    </div>
                </div>}
            
            {!loading && error.length > 0 &&
                <div className="flex justify-center items-center mt-44">
                    <div className="text-center">
                        <h1 className="text-2xl font-bold">Oops...</h1>
                        <p className="text-gray-500">{error}</p>
                    </div>
                </div>}
            
            {isModalOpen && (
                <AppModalShort title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Summarize</h2>
                            </div>
                            
                            <div onClick={handleCloseModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left my-auto">
                                <p className="font-semibold">
                                    Summary
                                </p>
                            </div>
                            
                            <div
                                className="bg-orange-200 text-gray-800 py-2 px-4 rounded-lg">
                                Tone of the blog: <strong>Friendly 🤝</strong>
                            </div>
                        </div>
                        
                        <div className="py-3 px-1">
                            <p className={"text-sm text-gray-700"}>
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                unknown printer took a galley of type and scrambled it to make a type specimen book. It
                                has survived not only five centuries, but also the leap into electronic typesetting,
                                remaining essentially unchanged. It was popularised in the 1960s with the release of
                                Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                                publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book. It has
                                survived not only five centuries, but also the leap into electronic typesetting,
                                remaining essentially unchanged. It was popularised in the 1960s with the release of
                                Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                                publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </p>
                            
                            <div className={"mt-8 text-sm"}>
                                <p className="font-bold mb-1">Related Products</p>
                                <p>We fetched some of the relatable products for your blog. You can add manually so to
                                    increase the sales.</p>
                            </div>
                            
                            <div className="flex overflow-x-auto mt-4 text-sm">
                                <div className="flex space-x-4">
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AppModalShort>
            )}
            
            {isEditArticleDetailModalOpen && (
                <AppModalMedium title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Edit Article Details</h2>
                            </div>
                            
                            <div onClick={handleCloseEditArticleDetailModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="py-3 px-1">
                            
                            <form className="flex flex-col gap-4">
                                <div>
                                    <div className="mb-2 block">
                                        <label
                                            htmlFor="title"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Title
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        id="title"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Enter Title"
                                        value={articleTitle}
                                        onChange={(e) => setArticleTitle(e.target.value)}
                                        required={true}
                                    />
                                </div>
                                
                                <div>
                                    <div className="mb-2 block">
                                        <label
                                            htmlFor="slug"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Slug
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        id="slug"
                                        className={`bg-gray-50 border ${slugValid ? 'border-gray-300' : 'border-red-500'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                        placeholder="Enter Slug"
                                        value={articleSlug}
                                        onChange={handleSlugChange}
                                        required={true}
                                    />
                                    {!slugValid && articleSlug && (
                                        <p className="text-red-500 text-xs mt-1">
                                            Slug must be lowercase, hyphenated, and contain no special characters or spaces.
                                        </p>
                                    )}
                                </div>
                                
                                <div className="flex flex-col md:flex-row md:space-x-4">
                                    <div className="w-full">
                                        <div className="flex">
                                            <label
                                                htmlFor="author"
                                                className="text-xs uppercase font-bold text-gray-600 dark:text-white"
                                            >
                                                Select Author
                                            </label>
                                            <div
                                                className="font-medium flex cursor-pointer text-blue-500 text-[14px] ml-auto"
                                                onClick={() => setShowAddAuthorField(true)}
                                            >
                                                <span>Add Author</span>
                                            </div>
                                        </div>
                                        <select
                                            id="author"
                                            required={true}
                                            onChange={(e) => setArticleAuthor(e.target.value)}
                                            value={articleAuthor}
                                            className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                            <option value="">Select Author</option>
                                            {authors.map((author, index) => (
                                                <option key={index} value={author}>
                                                    {author}
                                                </option>
                                            ))}
                                        </select>
                                        {showAddAuthorField && (
                                            <div className="mt-4">
                                                <input
                                                    type="text"
                                                    value={addNewAuthor}
                                                    onChange={(e) => setAddNewAuthor(e.target.value)}
                                                    placeholder="New Author"
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                                />
                                                <div className="flex mt-4">
                                                    <button
                                                        type="button"
                                                        onClick={handleAddNewAuthor}
                                                        className={`text-white hover:bg-red-500 bg-primary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 mr-2 mb-2 ${addNewAuthor.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                        disabled={addNewAuthor.trim() === ''}
                                                    >
                                                        Add Author
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => setShowAddAuthorField(false)}
                                                        className="text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 mr-2 mb-2"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    
                                    <div className="w-full">
                                        <div className="mb-2 flex">
                                            <label
                                                htmlFor="category"
                                                className="block text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Category
                                            </label>
                                            <div
                                                className="font-medium flex cursor-pointer text-blue-500 text-[14px] ml-auto"
                                                onClick={() => setShowAddCategoryField(true)}
                                            >
                                                <span>Add Category</span>
                                            </div>
                                        </div>
                                        <select
                                            id="category"
                                            required={true}
                                            onChange={(e) => setArticleCategory(e.target.value)}
                                            value={articleCategory}
                                            className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                            <option value="">Select Category</option>
                                            {categories.map((category, index) => (
                                                <option key={index} value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </select>
                                        {showAddCategoryField && (
                                            <div className="mt-4">
                                                <input
                                                    type="text"
                                                    value={addNewCategory}
                                                    onChange={(e) => setAddNewCategory(e.target.value)}
                                                    placeholder="New Category"
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                                />
                                                <div className="flex mt-4">
                                                    <button
                                                        type="button"
                                                        onClick={handleAddNewCategory}
                                                        className={`text-white hover:bg-red-500 bg-primary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 mr-2 mb-2 ${addNewCategory.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                        disabled={addNewCategory.trim() === ''}
                                                    >
                                                        Add Category
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => setShowAddCategoryField(false)}
                                                        className="text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 mr-2 mb-2"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                
                                </div>
                                
                                <button
                                    type="button"
                                    onClick={handleEditArticleDetail}
                                    className={`text-white mt-6 hover:bg-red-500 bg-primary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 ${editArticleSaving || !slugValid || !articleTitle ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                    disabled={editArticleSaving || !slugValid || !articleTitle}
                                >
                                    {!editArticleSaving ? 'Save' : 'Saving...'}
                                </button>
                            </form>
                        
                        </div>
                    </div>
                </AppModalMedium>
            )}
            
            {isPublishModalOpen && (
                <AppModalLong title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Publish Blog</h2>
                            </div>
                            
                            <div onClick={() => {
                                handleClosePublishModal();
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="pb-1 px-1">
                            
                            <DeployForm
                                article={article}
                                article_html={html}
                                handleClosePublishModal={() => {
                                    handleClosePublishModal();
                                }}
                                article_id={""}
                            />
                        
                        </div>
                    
                    </div>
                </AppModalLong>
            )}
            
            {/*<div className="h-screen flex justify-center items-center">*/}
            {showSEOReportView &&
                <>
                    {/*<div className="h-screen flex justify-center items-center">*/}
                    {/*    <div className="max-w-6xl mx-auto my-auto">*/}
                    {/*        <ChooseTemplate*/}
                    {/*            blog={currentBlog}*/}
                    {/*            isRouteChange={false}*/}
                    {/*            showSEO={showSEO}*/}
                    {/*            publishSelectedStatus={publishSelectedStatus}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="h-screen flex justify-center items-center">
                        <SEOReportView showSEOReportStatus={showSEOReportStatus}
                                       isSingleArticlePublish={true}
                                       isMigrate={true}
                                       blog={currentBlog}/>
                    </div>
                </>
            }
            
            {/*<>
                {showSEOReportView &&
                    <div className="h-screen flex justify-center items-center">
                        <SEOReportView showSEOReportStatus={showSEOReportStatus}
                                       isSingleArticlePublish={true}
                                       blog={currentBlog}/>
                    </div>}
            </>*/}
            {/*</div>*/}
        
        </div>
    )
}

export default EditorPage
