import React, {useEffect, useContext} from "react";
// import { Quill } from "react-quill";
import * as Quill from 'quill';
import API from '../../api/api'

// import { EmbedBlot } from 'parchment/dist/src/blot/embed';
import katex from "katex";
import "katex/dist/katex.min.css";
import {Button} from "antd";
import {DISPLAY_POSITION, PRODUCT_CARD_WIDGET, SOCIAL_MEDIA_WIDGET} from "../../constants/app.constants";

window.katex = katex;


const Inline = Quill.import('blots/inline');


const CustomUndo = () => (
    <svg viewBox="0 0 18 18">
        <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"/>
        <path
            className="ql-stroke"
            d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
        />
    </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
    <svg viewBox="0 0 18 18">
        <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"/>
        <path
            className="ql-stroke"
            d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
        />
    </svg>
);

const CustomToolTipWithImage = () => (
    <svg id="Layer_1"
         width="18" height="18" viewBox="0 0 64 64">
        <g>
            <path className="ql-fill ql-stroke" fill="#231F20" d="M60,0H4C1.789,0,0,1.789,0,4v56c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V4C64,1.789,62.211,0,60,0z
		 M53,54H11c-0.553,0-1-0.447-1-1s0.447-1,1-1h42c0.553,0,1,0.447,1,1S53.553,54,53,54z M53,48H11c-0.553,0-1-0.447-1-1s0.447-1,1-1
		h42c0.553,0,1,0.447,1,1S53.553,48,53,48z M11,28h18c0.553,0,1,0.447,1,1s-0.447,1-1,1H11c-0.553,0-1-0.447-1-1S10.447,28,11,28z
		 M10,23c0-0.553,0.447-1,1-1h18c0.553,0,1,0.447,1,1s-0.447,1-1,1H11C10.447,24,10,23.553,10,23z M11,34h18c0.553,0,1,0.447,1,1
		s-0.447,1-1,1H11c-0.553,0-1-0.447-1-1S10.447,34,11,34z M11,40h18c0.553,0,1,0.447,1,1s-0.447,1-1,1H11c-0.553,0-1-0.447-1-1
		S10.447,40,11,40z M54,41c0,0.553-0.447,1-1,1H35c-0.553,0-1-0.447-1-1V23c0-0.553,0.447-1,1-1h18c0.553,0,1,0.447,1,1V41z M62,12
		H2V4c0-1.104,0.896-2,2-2h56c1.104,0,2,0.896,2,2V12z"/>
            <path className="ql-fill ql-stroke" fill="#231F20" d="M7,4C5.343,4,4,5.343,4,7s1.343,3,3,3s3-1.343,3-3S8.657,4,7,4z M7,8C6.447,8,6,7.553,6,7s0.447-1,1-1
		s1,0.447,1,1S7.553,8,7,8z"/>
            <path className="ql-fill ql-stroke" fill="#231F20" d="M33,4H15c-1.657,0-3,1.343-3,3s1.343,3,3,3h18c1.657,0,3-1.343,3-3S34.657,4,33,4z M33,8H15
		c-0.553,0-1-0.447-1-1s0.447-1,1-1h18c0.553,0,1,0.447,1,1S33.553,8,33,8z"/>
        </g>
    </svg>
);

const CustomToolWithDeleteIcon = () => (
    <svg id="Layer_1"
         width="18" height="18" viewBox="0 0 64 64">
        <g>
            <path className="ql-fill ql-stroke" fill="#231F20" d="M60,0H4C1.789,0,0,1.789,0,4v56c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V4C64,1.789,62.211,0,60,0z
		 M53,54H11c-0.553,0-1-0.447-1-1s0.447-1,1-1h42c0.553,0,1,0.447,1,1S53.553,54,53,54z M53,48H11c-0.553,0-1-0.447-1-1s0.447-1,1-1
		h42c0.553,0,1,0.447,1,1S53.553,48,53,48z M11,28h18c0.553,0,1,0.447,1,1s-0.447,1-1,1H11c-0.553,0-1-0.447-1-1S10.447,28,11,28z
		 M10,23c0-0.553,0.447-1,1-fdkf.553,0,1,0.447,1,1s-0.447,1-1,1H11C10.447,24,10,23.553,10,23z M11,34h18c0.553,0,1,0.447,1,1
		s-0.447,1-1,1H11c-0.553,0-1-0.447-1-1S10.447,34,11,34z M11,40h18c0.553,0,1,0.447,1,1s-0.447,1-1,1H11c-0.553,0-1-0.447-1-1
		S10.447,40,11,40z dvnd,41c0,0.553-0.447,1-1,1H35c-0.553,0-1-0.447-1-1V23c0-0.553,0.447-1,1-1h18c0.553,0,1,0.447,1,1V41z M62,12
		H2V4c0-1.104,0.896-2,2-2h56c1.104,0,2,0.896,2,2V12z"/>
            <path className="ql-fill ql-stroke" fill="#231F20" d="M7,4C5.343,4,4,5.343,4,7s1.343,3,3,3s3-1.343,3-3S8.657,4,7,4z M7,8C6.447,8,6,7.553,6,7s0.447-1,1-1
		s1,0.447,1,1S7.553,8,7,8z"/>
            <path className="ql-fill ql-stroke" fill="#231F20" d="M33,4H15c-1.657,0-3,1.343-3,3s1.343,3,3,3h18c1.657,0,3-1.343,3-3S34.657,4,33,4z M33,8H15
		c-0.553,0-1-0.447-1-1s0.447-1,1-1h18c0.553,0,1,0.447,1,1S33.553,8,33,8z"/>
        </g>
    </svg>
);

// Undo and redo functions for Custom Toolbar
export function undoChange() {
    this.quill.history.undo();
}

function deleteChange() {
    this.quill.history.delete();
}

export function redoChange() {
    this.quill.history.redo();
}

function insertHeart() {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertText(cursorPosition, "♥");
    this.quill.setSelection(cursorPosition + 1);
}

function deleteHeart() {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.deleteText(cursorPosition, "♥");
    this.quill.setSelection(cursorPosition + 1);
}

function generateId() {
    const randomId = Math.random().toString(36).substr(2, 9);
    return randomId;
}

export function handleImageUpload() {

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
        const file = input.files[0];
        const fileName = file.name;
        const formData = new FormData();
        formData.append('image', file);
        formData.append('fileName', fileName);

        const headers = {
            headers : {
                "Authorization": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAsInV1aWQiOiJzdGh1eTEzNHYiLCJmaXJzdF9uYW1lIjoiSGFyaSIsImxhc3RfbmFtZSI6IlZpbm90aCIsImNvbnRhY3RfbnVtYmVyIjpudWxsLCJlbWFpbCI6InZpbm90aEBhYmMuY29tIiwicGFzc3dvcmQiOiJoYXJpIiwiaXNfYWN0aXZlIjp0cnVlLCJpc19zdWJzY3JpcHRpb25fYWN0aXZlIjpmYWxzZSwidXNlcl9pZF91c2VkX2J5X2FpX2dlbmVyYXRvciI6bnVsbCwiaXNfZmlyc3RfZnJlZV9hcnRpY2xlX2NyZWF0ZWQiOm51bGwsImNyZWF0ZWRfYXQiOiIyMDI0LTA3LTMxVDE3OjM0OjAxIiwidXBkYXRlZF9hdCI6IjIwMjQtMDctMzFUMTc6MzQ6MDEiLCJpYXQiOjE3MzE1NzIyODMsImV4cCI6MTc0MDIxMjI4M30.bScK-1BzwU5U4JFBb4uBqYUPtxZzTD1m8firs5KsleM'
            }
        }

        // const res = await API.post('articles/upload-image', formData, headers);
        const res = await API.post('articles/upload-image/fztxqmfsb/3s6fcvixg/09i8u8qxz/5vnu2dxbhl', formData, headers);

        const data = res.data;
        console.log("🚀 ~ input.onchange= ~ data:", data)
        const imageUrl = data.data.link;
        console.log("🚀 ~ input.onchange= ~ imageUrl:", data.data.link)

        const range = this.quill.getSelection();
        this.quill.insertEmbed(range.index, 'image', imageUrl);
        this.quill.setSelection(range.index + 1);
    };
}

// // Define your custom blot
class MyBlot extends Quill.import('blots/embed') {
    static create(value) {
        const node = super.create();
        node.innerHTML = value.content;
        node.setAttribute('id', value.id);
        // node.innerText =""
        // node.value=value.text;
        return node;
    }
    
    static value(node) {
        return {content: node.innerHTML};
    }
}

class DeleteBlot extends Quill.import('blots/embed') {
    static create(value) {
        const node = super.create();
        node.innerHTML = value.content;
        node.setAttribute('id', value.id);
        return node;
    }
    
    static value(node) {
        return {content: node.innerHTML};
    }
}

class SMBlot extends Quill.import('blots/embed') {
    static create(value) {
        const node = super.create();
        node.innerHTML = value.content;
        node.setAttribute('id', value.id);
        // node.innerText =""
        // node.value=value.text;
        return node;
    }
    
    static value(node) {
        return {content: node.innerHTML};
    }
}

// Register your custom blot with Quill
MyBlot.blotName = 'my-blot';
MyBlot.tagName = ['span', 'p', 'button'];
MyBlot.className = 'tooltip';
Quill.register(MyBlot);

// Register your custom blot with Quill
SMBlot.blotName = 'social-media-blot';
SMBlot.tagName = 'div';
SMBlot.className = 'test';
Quill.register(SMBlot);
// Quill.register({
//   'formats/my-blot': YoutubeEmbedBlot
// },true);

function getSelectedText() {
    var range = this.quill.getSelection();
    return;
};

const insertBlock = (id, text, inputRef, secondPageOp) => {
    const {widgetType} = secondPageOp.payload;
    if (widgetType === PRODUCT_CARD_WIDGET) {
        insertProductCardWidget(id, text, inputRef, secondPageOp);
    } else if (widgetType === SOCIAL_MEDIA_WIDGET) {
        insertSocialMediaWidget(id, text, inputRef, secondPageOp);
    }
}

const insertProductCardWidget = (id, text, inputRef, secondPageOp) => {
    if (text.length > 0) {
        inputRef.current.editor.format('youtubeEmbedBlot', id);
        const cursorPosition = inputRef.current.editor.getSelection().index;
        inputRef.current.editor.deleteText(cursorPosition, text.length);
        if (!!secondPageOp.payload && !!secondPageOp.payload.product_data) {
            if (secondPageOp.payload.template === 'left') {
                const content = `
                    ${text}
                    <span class="tooltiptext">
                      <div class="product-left">
                          <img src="https://placehold.co/600x400" alt="Product Image" class="product-image">
                          <div>
                            <div class="product-title-left">${secondPageOp.payload.product_data.title}</div>
                            <p class="product-price-left">$19.99</p>
                            <button class="product-button-left" onclick="window.open('https://www.shopify.com', '_blank');">Buy Now</button>
                          </div>
                          <span class="tooltiptext">
                          <div>
                            <div class="product-title-left">${secondPageOp.payload.product_data.title}</div>
                            <p class="product-price-left">$19.99</p>
                            <button class="product-button-left" onclick="window.open('https://www.shopify.com', '_blank');">Buy Now</button>
                          </div>
                          <span class="tooltiptext">
                              <div class="product-left">
                                  <img src="https://placehold.co/600x400" alt="Product Image" class="product-image">
                                  <div>
                                        <div class="product-title-left">${secondPageOp.payload.product_data.title}</div>
                                        <p class="product-price-left">$19.99</p>
                                        <button class="product-button-left" onclick="window.open('https://www.shopify.com', '_blank');">Buy Now</button>
                                  </div>
                              </div>
                          </span>
                        </div>
                    </span>`;
                inputRef.current.editor.insertEmbed(cursorPosition, 'my-blot', {
                    text: text,
                    id: id,
                    content: `${content}`,
                });
            } else if (secondPageOp.payload.template === 'center') {
                const content = `
                    ${text}
                    <span class="tooltiptext">
                          <div class="product-center">
                              <img src="https://placehold.co/600x400" alt="Product Image" class="product-image">
                              <div>
                                <div class="product-title-center">${secondPageOp.payload.product_data.title}</div>
                                <p class="product-price-center">$19.99</p>
                                <button class="product-button-center" onclick="window.open('https://www.shopify.com', '_blank');">Buy Now</button>
                              </div>
                            </div>
                        </span>`;
                inputRef.current.editor.insertEmbed(cursorPosition, 'my-blot', {
                    text: text,
                    id: id,
                    content: `${content}`,
                });
            }
        }
    }
}

const insertSocialMediaWidget = (id, text, inputRef, secondPageOp) => {
    inputRef.current.editor.format('youtubeEmbedBlot', id);
    const {
        displayPosition,
        displaySize,
        pinInterestUrl,
        instagramUrl,
        facebookUrl,
        twitterUrl,
        personalWebsiteUrl
    } = secondPageOp.payload.widgetSettings;
    const top = 0;
    const bottom = inputRef.current.editor.getLength();
    
    const content = `
        <div class="flex-icons">
          ${facebookUrl && `<a href="${facebookUrl}" class="icon-container" target="_blank">
            <svg class="icon" viewBox="0 0 24 24">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.5 0h-19A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h19A2.5 2.5 0 0024 21.5v-19A2.5 2.5 0 0021.5 0zm-2.53 5h-2.97c-.49 0-.93.22-1.24.56-.31.35-.52.86-.52 1.44v3.51h2.17l-.33 2.12h-1.84v7.37h-2.57V12.63h-1.8v-2.12h1.8V8.14c0-1.77 1.39-3.2 3.12-3.2h2.97V5z"/>
              </svg>
            </svg>
          </a>`}
          ${pinInterestUrl && `<a href="${pinInterestUrl}" class="icon-container" target="_blank">
            <svg class="icon" viewBox="0 0 24 24">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                 fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 0C5.383 0 0 5.383 0 12c0 5.305 4.063 9.617 9.25 9.95-.156-.914-.3-2.32.062-3.32.328-1.398 2.108-8.886 2.108-8.886s-.534-1.062-.534-2.632c0-2.463 1.425-4.308 3.19-4.308 1.498 0 2.226 1.125 2.226 2.478 0 1.506-.963 3.77-1.462 5.863-.415 1.875.88 3.389 2.725 3.389 3.27 0 5.792-4.628 5.792-9.357C24 5.383 18.617 0 12 0z"/>
                            </svg>
            </svg>
          </a>`}
          ${twitterUrl && `<a href="${twitterUrl}" class="icon-container" target="_blank">
            <svg class="icon" viewBox="0 0 24 24">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                 fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M24 4.525c-.885.387-1.83.65-2.823.772A5.275 5.275 0 0023.282 3.26a10.53 10.53 0 01-3.338 1.27A5.236 5.236 0 0016.554 0c-2.903 0-5.252 2.35-5.252 5.252 0 .412.047.813.14 1.198-4.36-.22-8.217-2.3-10.805-5.47A5.29 5.29 0 00.99 5.216c0 1.826.878 3.433 2.22 4.38a5.252 5.252 0 01-2.375-.65v.066c0 2.55 1.813 4.68 4.22 5.15a5.352 5.352 0 01-1.386.183c-.34 0-.668-.03-.993-.094.668 2.042 2.602 3.53 4.885 3.57a10.586 10.586 0 01-6.52 2.236c-.42 0-.83-.02-1.238-.058A14.898 14.898 0 007.9 21.42c9.688 0 14.987-8.02 14.987-14.986 0-.23-.005-.46-.015-.688A10.692 10.692 0 0024 4.526z"/>
                            </svg>
            </svg>
          </a>`}
          ${instagramUrl && `<a href="${instagramUrl}" class="icon-container" target="_blank">
            <svg class="icon" viewBox="0 0 24 24">
                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                     fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12 0a12 12 0 00-9.086 19.845A11.8 11.8 0 0012 24a11.8 11.8 0 009.086-4.155A12 12 0 0012 0zm0 3.7a8.3 8.3 0 018.3 8.3 8.3 8.3 0 01-8.3 8.3 8.3 8.3 0 01-8.3-8.3A8.3 8.3 0 0112 3.7zm0 2.413a5.887 5.887 0 00-5.875 5.875A5.887 5.887 0 0012 17.863a5.887 5.887 0 005.875-5.875A5.887 5.887 0 0012 6.114zm5.3.75a.7.7 0 11.7-.7.7.7 0 01-.7.7zM12 7.338a4.662 4.662 0 00-4.657 4.657A4.662 4.662 0 0012 16.653a4.662 4.662 0 004.657-4.657A4.662 4.662 0 0012 7.338zm0 2.094a2.57 2.57 0 012.566 2.566A2.57 2.57 0 0112 14.564a2.57 2.57 0 01-2.566-2.566A2.57 2.57 0 0112 9.432z"/>
                                </svg>
            </svg>
          </a>`}
          ${personalWebsiteUrl && `<a href="${personalWebsiteUrl}" class="icon-container" target="_blank">
            <svg class="icon" viewBox="0 0 24 24">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
            </svg>
          </a>`}
        </div>
    `
    
    if (displayPosition === DISPLAY_POSITION[0]) {
        inputRef.current.editor.insertEmbed(top, 'social-media-blot', {
            id: id,
            content: `${content}`,
        });
    } else if (displayPosition === DISPLAY_POSITION[1]) {
        inputRef.current.editor.insertEmbed(bottom, 'social-media-blot', {
            id: id,
            content: `${content}`,
        });
    }
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
    "arial",
    "comic-sans",
    "courier-new",
    "georgia",
    "helvetica",
    "lucida"
];
Quill.register(Font, true);

export const modules = {
    toolbar: {
        container: "#toolbar",
        handlers: {
            undo: undoChange,
            redo: redoChange,
            // youtubeEmbedBlots: insertBlock,
        }
    },
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

export const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block", "formula", "youtubeEmbedBlot", "my-blot", "social-media-blot"
];

// Quill Toolbar component
export const QuillToolbar = (props) => {
    
    useEffect(() => {
        if (!!props.secondPageOp?.text) {
            const id = generateId();
            insertBlock(id, props.selectedText, props.inputRef, props.secondPageOp || {});
            props.setSecondPageOp({})
            props.setCollapsed(!props.collapsed);
        }
    }, [props.secondPageOp]);
    
    return (
        
        <div id="toolbar" className={"w-[100%] text-center"}>
            <span className="ql-formats">
                <select className="ql-header" defaultValue="3">
                    <option value="1">Heading</option>
                    <option value="2">Subheading</option>
                    <option value="3">Normal</option>
                </select>
            </span>
            
            <span className="ql-formats">
              <button className="ql-bold"/>
              <button className="ql-italic"/>
              <button className="ql-underline"/>
              <button className="ql-strike"/>
            </span>
            
            <span className="ql-formats">
              <button className="ql-list" value="ordered"/>
              <button className="ql-list" value="bullet"/>
              <button className="ql-indent" value="-1"/>
              <button className="ql-indent" value="+1"/>
            </span>
            
            <span className="ql-formats">
              <select className="ql-align"/>
              <select className="ql-color"/>
              <select className="ql-background"/>
            </span>
            
            <span className="ql-formats">
              <button className="ql-link"/>
              <button className="ql-image"/>
              <button className="ql-video"/>
            </span>
            
            <span className="ql-formats">
              <button className="ql-undo">
                <CustomUndo/>
              </button>
              <button className="ql-redo">
                <CustomRedo/>
              </button>
            </span>
            
            <span className="ql-formats">
              <button className=" trigger ql-youtubeEmbedBlot"
                      onClick={() => props.setCollapsed(!props.collapsed)}>
                <CustomToolTipWithImage/>
              </button>
            </span>
        
        </div>
    );
}

export default QuillToolbar;
