// Page used for testing purposes only
// This page is not accessible to the public

import React, {useEffect, useContext, useState} from 'react';
import {useLocation} from "react-router";
import Sidebar from "../components/layouts/Sidebar";
import AppContext from "../context/AppContext";
import API from "../api/api";
import { toastSuccess, toastError } from '../context/ToasterContext';
import { useNavigate } from "react-router-dom";

export default function AdminTesting() {
    const location = useLocation();
    let { initialDataFetch, token, user, account, store, triggerConfetti } =
    useContext(AppContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    // Function to unpublish the blog
    const handleUnpublishBlog = () => {
        try{
            setLoading(true);
        console.log("Unpublishing blog");
        const blogId = document.getElementById('blogId').value;
        const testKey = document.getElementById('testKey').value;
        console.log(blogId);
        const config = {
            headers: {
                "Authorization": token
            }
        }
        const payload = {
            blogId: blogId,
            testKey: testKey
        }
        API.post('/admintest/unpublishBlogForTesting', payload, config)
        .then((response) => {
            console.log(response.data);
            if (response.data.status === 200) {
                toastSuccess("Blog unpublished successfully");
                setLoading(false);
            } else {
                toastError(response.data.message);
                setLoading(false);  
            }
        })
        .catch((error) => {
            console.log(error);
            toastError("Error in unpublishing blog");
            setLoading(false);
        })  
        } catch (error) {
            console.log(error);
            toastError("Error in unpublishing blog - main catch block");
            setLoading(false);
        }
    }


    return (
        <>
        <div className="flex justify-between">
        {/* Home button routing to /blogs */}
        <div className="p-4">
            <button className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={() => {
                    navigate('/blogs');
                }}
            >Home</button>
        </div>
        {/* Loader */}
        <div className="p-4">
        {loading && <div className="loader">
            status:
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-red-700 rounded-full" role="status">
                <span className="visually-hidden"></span>
            </div>
            </div>}
        </div>
        </div>

        <div className='inline-flex gap-2'>
        {/* Text field to input test key */}
        <div className="p-4">
            <h2 className="text-xl font-bold">Test Key</h2>
            <input type="text" id='testKey' placeholder="Enter Test Key"/>
        </div>
        {/* Text field to input user id */}
        <div className="p-4">
            <h2 className="text-xl font-bold">User ID</h2>
            <input type="text" id='userId' placeholder="Enter User ID"/>
        </div>
        {/* Text field to input account id */}
        <div className="p-4">
            <h2 className="text-xl font-bold">Account ID</h2>
            <input type="text" id='accountId' placeholder="Enter Account ID"/>
        </div>
        {/* Text field to input store id */}
        <div className="p-4">
            <h2 className="text-xl font-bold">Store ID</h2>
            <input type="text" id='storeId' placeholder="Enter Store ID"/>
        </div>
                {/* Text field to input blog id */}
                <div className="p-4">
            <h2 className="text-xl font-bold">Blog ID</h2>
            <input type="text" id='blogId' placeholder="Enter Blog ID"/>
        </div>
        {/* Text field to input article id */}
        <div className="p-4">
            <h2 className="text-xl font-bold">Article ID</h2>
            <input type="text" id='articleId' placeholder="Enter Article ID"/>
        </div>

        </div>
        <h2 className="text-xl font-bold m-3 pl-3">Blogs</h2>
        <div className='flex gap-2'>
        {/* Button to unpublish the blog with input blog id*/}
        <div className="p-4">
            <button className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleUnpublishBlog}
            >Unpublish</button>
        </div>
        {/* Button to delete all blogs */}
        <div className="p-4">
            <button className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={() => {
                    console.log("Deleting all blogs");
                    const testKey = document.getElementById('testKey').value;
                    const accountId = document.getElementById('accountId').value;
                    const storeId = document.getElementById('storeId').value;
                    const config = {
                        headers: {
                            "Authorization": token
                        }
                    }
                    const payload = {
                        testKey: testKey,
                        accountId: accountId,
                        storeId: storeId
                    }
                    API.post('/admintest/deleteAllBlogsForTesting', payload, config)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status === 200) {
                            toastSuccess("All blogs deleted successfully");
                        } else {
                            toastError(response.data.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toastError("Error in deleting all blogs");
                    })  
                }}
            >Delete All</button>
        </div>
        </div>

        <h2 className="text-xl font-bold m-3 pl-3">Plans</h2>
        <div className='flex gap-2'>
        {/* Button to delete plans */}
        <div className="p-4">
            <button className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={() => {
                    console.log("Deleting all plans");
                    const testKey = document.getElementById('testKey').value;
                    const userId = document.getElementById('userId').value;
                    const config = {
                        headers: {
                            "Authorization": token
                        }
                    }
                    const payload = {
                        testKey: testKey,
                        userId: userId
                    }
                    API.post('/admintest/deletePlansForTesting', payload, config)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status === 200) {
                            toastSuccess("All plans deleted successfully");
                        } else {
                            toastError(response.data.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toastError("Error in deleting all plans");
                    })  
                }}
            >Delete</button>
        </div>
        </div>
        {/* Store */}
        <h2 className="text-xl font-bold m-3 pl-3">Store</h2>
        <div className='flex gap-2'>
        {/* Button to delete store */}
        <div className="p-4">
            <button className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={() => {
                    console.log("Deleting store");
                    const testKey = document.getElementById('testKey').value;
                    const accountId = document.getElementById('accountId').value;
                    const storeId = document.getElementById('storeId').value;
                    const config = {
                        headers: {
                            "Authorization": token
                        }
                    }
                    const payload = {
                        testKey: testKey,
                        accountId: accountId,
                        storeId: storeId
                    }
                    API.post('/admintest/deleteStoreForTesting', payload, config)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status === 200) {
                            toastSuccess("Store deleted successfully");
                        } else {
                            toastError(response.data.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toastError("Error in deleting store");
                    })  
                }}
            >Delete</button>
        </div>
        </div>
        {/* User */}
        <h2 className="text-xl font-bold m-3 pl-3">User</h2>
        <div className='flex gap-2'>
        {/* Button to delete user */}
        <div className="p-4">
            <button className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={() => {
                    console.log("Deleting user");
                    const testKey = document.getElementById('testKey').value;
                    const userId = document.getElementById('userId').value;
                    const config = {
                        headers: {
                            "Authorization": token
                        }
                    }
                    const payload = {
                        testKey: testKey,
                        userId: userId
                    }
                    API.post('/admintest/deleteUserForTesting', payload, config)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status === 200) {
                            toastSuccess("User deleted successfully");
                        } else {
                            toastError(response.data.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toastError("Error in deleting user");
                    })  
                }}
            >Delete</button>
        </div>
        </div>
        </>
    )
}