import React, {useContext, useEffect, useState} from "react";
import AppContext from "../../context/AppContext";
import {
    PRODUCT_CARD_WIDGET,
    STORE_UUID,
    STYLE_STRING,
    TEMPLATE_STRING,
    VISIBILITY_STRING
} from "../../constants/app.constants";
import API from "../../api/api";
import {useLocation} from "react-router";
import {currency} from '../../config/currency';

const EmbedProductCard = ({editor, editors, setSelectedEditor, applyProductWidget}) => {
    
    const location = useLocation();
    let {initialDataFetch, token, user, account, store, afterLogout} = useContext(AppContext);
    const [visibilityValue, setVisibilityValue] = useState(VISIBILITY_STRING[0]);
    const [styleValue, setStyleValue] = useState(STYLE_STRING[0]);
    const [colorValue, setColorValue] = useState('#000000');
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    
    const [loading, setLoading] = useState(false);
    const [applyLoading, setApplyLoading] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [allCollections, setAllCollections] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const {blog_uuid, article_uuid} = location.state;
    const [selectedItemFromChild, setSelectedItemFromChild] = useState(null);
    
    useEffect(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(editor.content, 'text/html');
        const productElement = doc.querySelector('[data-product-id]');
        
        if (productElement) {
            const productData = JSON.parse(productElement.getAttribute('data-product-id'));
            setSelectedItemFromChild(productData);
        }
    }, [editor.content]);
    
    useEffect(() => {
        fetchAllProducts();
    }, []);
    
    const fetchAllProducts = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.get(`shopify/products/${account}/${store_uuid}`, headerParams)
            .then(res => {
                if (res.data.data.products.length > 0) {
                    setAllItems(res.data.data.products);
                } else {
                    setAllItems([]);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setLoading(false);
            })
    }
    
    const fetchAllCollections = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.get(`shopify/products/collections/${account}/${store_uuid}`, headerParams)
            .then(res => {
                if (res.data.data.collections.collects.length > 0) {
                    setAllItems(res.data.data.collections.collects);
                } else {
                    setAllItems([]);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setLoading(false);
            })
    }
    
    const createProductWidget = (payload) => {
        setApplyLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.post(`widgets/product_card/create/${account}/${store_uuid}/${blog_uuid}/${article_uuid}`, payload, headerParams)
            .then(res => {
                const {data} = res.data;
                setApplyLoading(false);
            })
            .catch(error => {
                setApplyLoading(false);
            })
    }
    
    const deleteWidget = id => {
        const updatedEditors = editors.filter(editor => editor.id !== id);
        setSelectedEditor(updatedEditors);
    };
    
    const handleProductChange = (event) => {
        const selectedValue = event.target.value;
        const selectedProduct = JSON.parse(selectedValue);
        setSelectedItemFromChild(selectedProduct);
    };
    
    const selectedValue = selectedItemFromChild ? JSON.stringify(selectedItemFromChild) : "";
    
    const handleColorChange = (event) => {
        setColorValue(event.target.value);
    };
    
    /*const changeStyleValue = (value) => {
        if (value === STYLE_STRING[0]) {
            fetchAllProducts();
        } else if (value === STYLE_STRING[1]) {
            fetchAllCollections();
        } else if (value === STYLE_STRING[2]) {
            setAllItems([]);
        }
        setStyleValue(value);
    }*/
    
    return (
        <>
            <div className="flex items-center justify-center">
                <div className="border rounded-lg my-2 bg-white shadow max-w-sm mx-2">
                    <div className="m-8">
                        
                        <div className="flex justify-between items-center">
                            <h2 className="text-lg font-semibold text-gray-900">Product Card</h2>
                            <button className="text-gray-700 hover:text-gray-900" onClick={() => {
                                deleteWidget(editor.id)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        
                        {/*<>
                    <label htmlFor="style"
                           className="block mb-2 mt-8 text-sm font-medium text-gray-900 dark:text-white ml-2">Select Style</label>
                    <div className="border border-gray-300 rounded-full p-1 inline-block">
                        <div className="flex">
                            <button
                                className={`${styleValue === STYLE_STRING[0] ? 'bg-primary text-white' : ''} font-semibold py-2 px-4 rounded-full mr-1`}
                                onClick={() => {
                                    changeStyleValue(STYLE_STRING[0]);
                                }}>
                                Fixed
                            </button>
                            <button
                                className={`${styleValue === STYLE_STRING[1] ? 'bg-primary text-white' : ''} font-semibold py-2 px-4 rounded-full ml-1`}
                                onClick={() => {
                                    changeStyleValue(STYLE_STRING[1]);
                                }}>
                                Dynamic
                            </button>
                            <button
                                className={`${styleValue === STYLE_STRING[2] ? 'bg-primary text-white' : ''} font-semibold py-2 px-4 rounded-full ml-1`}
                                onClick={() => {
                                    changeStyleValue(STYLE_STRING[2]);
                                }}>
                                Random
                            </button>
                        </div>
                    </div>
                </>*/}
                        
                        {/*{styleValue !== STYLE_STRING[2] &&
                    <>
                        <label htmlFor="product"
                               className="block mb-2 mt-6 text-sm font-medium text-gray-900 dark:text-white ml-2">
                            Select {styleValue === STYLE_STRING[0] ? 'Product' : 'Collection'}
                        </label>
                        <select
                            id="product"
                            disabled={loading}
                            onChange={handleProductChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            {loading ?
                                <option value="" className={"bg-gray-900"}>
                                    Please wait,
                                    fetching {styleValue === STYLE_STRING[0] ? 'products' : 'collections'}....
                                </option> :
                                <option value="">
                                    Choose {styleValue === STYLE_STRING[0] ? 'Product' : 'Collection'}
                                </option>
                            }
                            {allItems.map((item) => (
                                <option key={item.id} value={JSON.stringify(item)}>
                                    {styleValue === STYLE_STRING[0] ? item.title : item.collection_id}
                                </option>
                            ))}
                        </select>
                    </>}*/}
                        
                        <>
                            <label htmlFor="product"
                                   className="block mb-2 mt-6 text-sm font-medium text-gray-900 dark:text-white ml-2">
                                Select Product
                            </label>
                            <select
                                id="product"
                                disabled={loading}
                                onChange={handleProductChange}
                                value={selectedValue}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                {loading ? (
                                    <option value="" className="bg-gray-900">
                                        Please Wait. Fetching Product List
                                    </option>
                                ) : (
                                    <option value="">
                                        Choose Product
                                    </option>
                                )}
                                {allItems.map((item) => (
                                    <option key={item.id} value={JSON.stringify(item)}>
                                        {item.title}
                                    </option>
                                ))}
                            </select>
                        </>
                        
                        <>
                            <label htmlFor="color"
                                   className="block mb-2 mt-6 text-sm font-medium text-gray-900 dark:text-white ml-2">Choose
                                Button Color</label>
                            <input
                                type="color"
                                id="color"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="John"
                                required
                                onChange={handleColorChange}
                            />
                        </>
                        
                        <div className="mt-8 mb-2">
                            <div className="flex justify-between">
                                <button
                                    disabled={loading}
                                    onClick={() => {
                                        const payload = {
                                            widgetType: PRODUCT_CARD_WIDGET,
                                            button_color: colorValue,
                                            product_data: selectedItemFromChild,
                                        }
                                        createProductWidget(payload);
                                        applyProductWidget(editor.id, payload);
                                    }}
                                    className={`bg-primary text-white font-semibold py-2 px-4 w-full rounded-lg ml-1`}>
                                    {applyLoading ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </div>
                    
                    </div>
                </div>
                
                {/*<div className="border rounded-lg my-2 mt-3 bg-white shadow max-w-sm mx-2">*/}
                <div className="m-8 m ml-4">
                    <div className="border shadow-lg rounded-lg py-1 px-4">
                        <div data-id="product_card" data-metadata="product_card"
                             className="mx-auto p-4 flex flex-col items-center">
                                {selectedItemFromChild && selectedItemFromChild.pics.length ?
                                <img src={selectedItemFromChild.pics[0]} alt="Product Image" className="w-36 max-w-full rounded-lg shadow-md"/>
                                :
                            <img src="https://plus.unsplash.com/premium_photo-1670426502067-99395cc491c2"
                                 alt="Product Image" className="w-36 max-w-full rounded-lg shadow-md"/>
                                }
                            <div className="text-center mt-4">
                                <div className="font-bold mb-3"
                                     data-product-id='${JSON.stringify(content.product_data)}'>
                                    {selectedItemFromChild ? selectedItemFromChild.title : 'Product Title'}</div>
                                <div className="font-semibold text-lg mb-3">
                                 {currency} {" "}{selectedItemFromChild ? selectedItemFromChild.price : 'Price'}
                                </div>
                                <button
                                    className={`bg-primary text-white font-semibold py-2 px-4 rounded-lg ml-1`}
                                    style={{backgroundColor: colorValue}}
                                >
                                    Buy Now 
                                </button>
                            </div>
                        </div>
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    )
};

export default EmbedProductCard;
